import {delay} from '../utils/promises';
import fakeStockPrices from './FakeStockData'
import {formatDate} from "../utils/dates.tsx";

export interface StockPrice {
    key: string;
    ticker: string;
    price: number;
    targetPrice: number;
    percentage: number;
}

export interface StockDetailResponse {
    name: string;
    ticker: string;
    industries: string[];
    trend: {
        duration: string;
        prices: number[];
        targetPrices: number[];
        events: any[];
    }
}

export default class StockApiClient {
    async getStocks(date: Date): Promise<StockPrice[]> {
        const dateString = formatDate(date)
        const response = await fetch(`/api/stocks/${dateString}`)
        const {stocks} = await response.json()
        return stocks
    }

    async getStockDetail(ticker: string): Promise<StockDetailResponse> {
        const response = await fetch(`/api/stocks/detail/${ticker.toUpperCase()}`)
        return await response.json()
    }

    async getAvailableTickers(): Promise<string[]> {
        await delay(1000)
        return fakeStockPrices.map(stock => stock.ticker)
    }
}
