import React from 'react';
import {Card, Descriptions, DescriptionsProps, Tag, Typography} from 'antd';
import {Layout} from '../../Layout.tsx';
import {StockDetailResponse} from "../../apiclients/StockApiClient.ts";
import {TrendChart} from "./TrendChart.tsx";
import {format, subWeeks} from "date-fns";
import {Link} from "react-router-dom";

export interface DashboardProps {
    meta: {
        shortName: string,
        fullExchangeName: string,
        regularMarketPrice: number,
        currency: string,
        regularMarketTime: number,
    } | undefined,
    detail: StockDetailResponse | undefined,
    loading: boolean;
}

export function StockDetail({meta, detail, loading}: DashboardProps) {
    if (loading) {
        return (
            <Layout breadcrumbs={[
                {key: 'stocks', title: 'Stocks'},
            ]}>
                <Card title={detail?.ticker}>
                    {loading ? 'loading' : JSON.stringify(detail, null, 2)}
                </Card>
            </Layout>
        )
    }
    if (meta === undefined || detail === undefined) {
        return (
            <Card>
                Fail to load data
            </Card>
        )
    }

    const items: DescriptionsProps['items'] = [
        {
            key: 'exchange',
            label: 'Exchange',
            children: meta.fullExchangeName,
        },
        {
            key: 'ticker',
            label: 'Ticker',
            children: detail.ticker
        },
        {
            key: 'links',
            label: 'Links',
            children: (
                <>
                    <Link to={`https://finance.yahoo.com/quote/${detail.ticker}/`}>
                        <Tag>
                            yahoo finance
                        </Tag>
                    </Link>
                    <Link to={`https://finviz.com/quote.ashx?t=${detail.ticker}&p=d`}>
                        <Tag>
                            finviz
                        </Tag>
                    </Link>
                    <Link to={`https://www.google.com/search?q=${detail.ticker}&tbm=nws`}>
                        <Tag>
                            google news
                        </Tag>
                    </Link>
                </>
            )
        }
        // {
        //     key: 'industry',
        //     label: 'Industry',
        //     children: (
        //         detail.industries.map(industry => (
        //             <>
        //                 {industry}
        //                 <br/>
        //             </>
        //         ))
        //     ),
        // },
    ]

    return (
        <Layout breadcrumbs={[
            {key: 'stocks', title: 'Stocks'},
            {key: 'stockDetail', title: detail.ticker},
        ]}>
            <Typography.Title>{meta.shortName}</Typography.Title>
            <Typography>
                <b>{`${meta.currency} ${meta.regularMarketPrice} `}</b>
                <i>{`(at ${format(new Date(meta.regularMarketTime * 1000), 'MM/dd hh:mm:ss')})`}</i></Typography>
            <Descriptions size='small' layout='vertical' items={items}/>
            <Card>
                <TrendChart
                    data={[
                        {
                            name: 'Price',
                            values: detail.trend.prices,
                        },
                        {
                            name: 'Target Price',
                            values: detail.trend.targetPrices,
                        }
                    ]}
                    start={subWeeks(new Date(), 12)}
                    end={new Date()}
                />
                {/*{JSON.stringify(detail.trend, null, 2)}*/}
            </Card>
        </Layout>
    )
}
