import { StockPrice } from './StockApiClient';

const data: StockPrice[] = [
    {
        key: 'ARM',
        ticker: 'ARM',
        price: 160.77,
        targetPrice: 121.17,
        percentage: -24.63,
    },
    {
        key: 'JPM',
        ticker: 'JPM',
        price: 198.67,
        targetPrice: 213.05,
        percentage: 7.24,
    },
    {
        key: 'NVDA',
        ticker: 'NVDA',
        price: 130.78,
        targetPrice: 127.39,
        percentage: -2.59,
    },
    {
        key: 'SBUX',
        ticker: 'SBUX',
        price: 79.72,
        targetPrice: 88.5,
        percentage: 11.01,
    },
    {
        key: 'V',
        ticker: 'V',
        price: 280.1,
        targetPrice: 309.75,
        percentage: 10.59,
    },
    {
        key: 'PG',
        ticker: 'PG',
        price: 167.64,
        targetPrice: 171.85,
        percentage: 2.51,
    },
    {
        key: 'KEYS',
        ticker: 'KEYS',
        price: 157.3,
        targetPrice: 162.33,
        percentage: 3.2,
    },
    {
        key: 'JNJ',
        ticker: 'JNJ',
        price: 154.64,
        targetPrice: 172.65,
        percentage: 11.65,
    },
    {
        key: 'TSLA',
        ticker: 'TSLA',
        price: 177.46,
        targetPrice: 183.03,
        percentage: 3.14,
    },
    {
        key: 'O',
        ticker: 'O',
        price: 55.13,
        targetPrice: 60.23,
        percentage: 9.25,
    },
    {
        key: 'AMZN',
        ticker: 'AMZN',
        price: 184.7,
        targetPrice: 222.26,
        percentage: 20.34,
    },
    {
        key: 'GOOGL',
        ticker: 'GOOGL',
        price: 176.06,
        targetPrice: 191.96,
        percentage: 9.03,
    },
    {
        key: 'AAPL',
        ticker: 'AAPL',
        price: 189.87,
        targetPrice: 201.56,
        percentage: 6.16,
    },
    {
        key: 'MSFT',
        ticker: 'MSFT',
        price: 420.21,
        targetPrice: 481.03,
        percentage: 14.47,
    },
    {
        key: 'ABBV',
        ticker: 'ABBV',
        price: 166.42,
        targetPrice: 183.08,
        percentage: 10.01,
    },
    {
        key: 'KO',
        ticker: 'KO',
        price: 63.03,
        targetPrice: 67.84,
        percentage: 7.63,
    },
    {
        key: 'TTWO',
        ticker: 'TTWO',
        price: 147.84,
        targetPrice: 174.16,
        percentage: 17.8,
    },
    {
        key: 'CDNS',
        ticker: 'CDNS',
        price: 288.81,
        targetPrice: 325.87,
        percentage: 12.83,
    },
    {
        key: 'WMT',
        ticker: 'WMT',
        price: 64.65,
        targetPrice: 70.73,
        percentage: 9.4,
    },
    {
        key: 'BLK',
        ticker: 'BLK',
        price: 812.22,
        targetPrice: 915.31,
        percentage: 12.69,
    },
    {
        key: 'QCOM',
        ticker: 'QCOM',
        price: 193.86,
        targetPrice: 191.37,
        percentage: -1.28,
    },
    {
        key: 'WM',
        ticker: 'WM',
        price: 210.44,
        targetPrice: 226.71,
        percentage: 7.73,
    },
    {
        key: 'XOM',
        ticker: 'XOM',
        price: 119.64,
        targetPrice: 133.03,
        percentage: 11.19,
    },
    {
        key: 'SNPS',
        ticker: 'SNPS',
        price: 566.73,
        targetPrice: 641.44,
        percentage: 13.18,
    },
    {
        key: 'AMAT',
        ticker: 'AMAT',
        price: 212.08,
        targetPrice: 233.12,
        percentage: 9.92,
    },
    {
        key: 'ABT',
        ticker: 'ABT',
        price: 104.09,
        targetPrice: 126.68,
        percentage: 21.7,
    },
    {
        key: 'LOW',
        ticker: 'LOW',
        price: 231.11,
        targetPrice: 253.15,
        percentage: 9.54,
    },
    {
        key: 'ASML',
        ticker: 'ASML',
        price: 924.97,
        targetPrice: 1095.18,
        percentage: 18.4,
    },
    {
        key: 'PEP',
        ticker: 'PEP',
        price: 182.19,
        targetPrice: 187.93,
        percentage: 3.15,
    },
    {
        key: 'AVGO',
        ticker: 'AVGO',
        price: 1395.29,
        targetPrice: 1552.82,
        percentage: 11.29,
    },
    {
        key: 'INTC',
        ticker: 'INTC',
        price: 31.83,
        targetPrice: 38.32,
        percentage: 20.39,
    },
]

export default data
