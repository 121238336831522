import React from 'react';
import {Card, DatePicker, Table, TableColumnsType, Tag} from 'antd';
import dayjs from 'dayjs';
import {Layout} from '../../Layout.tsx';
import {StockPrice} from '../../apiclients/StockApiClient.ts';
import {Link} from "react-router-dom";

export interface DashboardProps {
    date: Date,
    stockPrices: StockPrice[];
    onChangeDate: (date: Date) => void;
    loading: boolean;
}

const columns: TableColumnsType<StockPrice> = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        width: '50%',
        render: (_, {ticker}) => (
            <Link to={`/stocks/${ticker}`}>{ticker}</Link>
        )
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
    },
    {
        title: 'Target',
        dataIndex: 'targetPrice',
        key: 'targetPrice',
        align: 'right',
    },
    {
        title: 'T/P (%)',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'right',
        sorter: (a, b) => a.percentage - b.percentage,
        sortDirections: ['ascend', 'descend'],
        render: (_, {percentage}) => (
            <Tag
                style={{width: '68px', textAlign: 'right'}}
                color={percentage < 0 ? 'blue' : 'red'}
            >
                {percentage.toFixed(2)}%
            </Tag>
        )
    },
]

export function StockList({stockPrices, date, onChangeDate, loading}: DashboardProps) {
    return (
        <Layout breadcrumbs={[
            {key: 'stocks', title: 'Stocks'},
        ]}>
            <Card>
                <DatePicker
                    minDate={dayjs('2024-04-15')}
                    defaultValue={dayjs(date)}
                    onChange={dayjsDate => onChangeDate(dayjsDate.toDate())}
                    inputReadOnly={true}
                    allowClear={false}
                />
            </Card>
            <Card title='US'>
                <Table
                    columns={columns}
                    dataSource={stockPrices}
                    size='small'
                    loading={loading}
                    pagination={{pageSize: 20}}
                />
            </Card>
        </Layout>
    )
}
