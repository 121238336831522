import React, {useEffect, useState} from 'react'
import StockApiClient, {StockPrice} from '../../apiclients/StockApiClient.ts'
import {StockList} from './StockList.tsx'
import {formatDate, getTargetDate, parseDateString} from "../../utils/dates.tsx";
import {useNavigate, useParams} from "react-router-dom";

const stockApiClient = new StockApiClient()

export default function StockListPage() {
    const {date: dateString} = useParams<'date'>()
    const navigate = useNavigate();
    const [date, setDate] = useState(getTargetDate(parseDateString(dateString)))
    const [stockPrices, setStockPrices] = useState<StockPrice[]>([])
    const [loading, setLoading] = useState(false)

    const handleChangeDate = (date: Date) => {
        setDate(date)
        navigate(`/stocks/all/${formatDate(date)}`)
    }

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => stockApiClient.getStocks(date))
            .then(data => setStockPrices(data.map(stock => ({...stock, key: stock.ticker}))))
            .finally(() => setLoading(false))
    }, [date])

    return (
        <StockList
            date={date}
            onChangeDate={handleChangeDate}
            stockPrices={stockPrices}
            loading={loading}
        />
    )
}
