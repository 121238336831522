import React, {useRef} from "react";

import bb, {line} from "billboard.js";
// import "billboard.js/dist/billboard.css"; // default css
import "billboard.js/dist/theme/dark.css";
import BillboardJS, {IChart} from "@billboard.js/react";
import {eachDayOfInterval} from "date-fns";

export interface TrendChartProps {
    data: {
        name: string,
        values: number[],
    }[],
    start: Date,
    end: Date,
}

export function TrendChart({data, start, end}: TrendChartProps) {
    // to get the instance, create ref and pass it to the component
    const chartComponent = useRef<IChart>(null);

    // useEffect(() => {
    //     // get the instance from ref
    //     const chart = chartComponent.current?.instance;
    //
    //     // call APIs
    //     if (chart) {
    //         chart.load();
    //     }
    // }, []);

    return <BillboardJS
        bb={bb}
        options={{
            data: {
                x: 'x',
                columns: [
                    ['x', ...eachDayOfInterval({start, end})],
                    ...data.map(({name, values}) => [name, ...values]),
                ],
                type: line(),
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        count: 12,
                        format: '%m/%d',
                    },
                },
                y: {
                    label: 'US Dollar'
                },
            },
            point: {
                show: false,
            },
            tooltip: {
                show: true,
            }
        }}
        ref={chartComponent}

        /* The values will be specified to the bound element as inlined styles */
        style={{}}

        /* When class name doesn't contains `bb`,
           then you also need to update the default CSS to be rendered correctly. */
        className={"bb my-classname"}
    />;
}
