import React from 'react';
import { Breadcrumb, Space } from 'antd';

export interface BreadcrumbItem {
    key: string;
    title: string;
}

export interface LayoutProps {
    children: React.ReactNode;
    breadcrumbs: BreadcrumbItem[];
}

export function Layout({ children, breadcrumbs }: LayoutProps) {
    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbs} />
            <Space direction='vertical' style={{ display: 'flex' }}>
                {children}
            </Space>
        </>
    );
}
