import {subDays} from "date-fns";

export interface ChartResponse {
    chart: {
        result: {
            meta: {
                shortName: string,
                fullExchangeName: string,
                regularMarketPrice: number,
                currency: string,
                regularMarketTime: number,
            }
        }[]
    }
}

export default class YahooFinancialApiClient {
    async getStock(ticker: string): Promise<ChartResponse> {
        const now = Math.floor(new Date().getTime() / 1000000) * 1000
        const yesterday = Math.floor(subDays(new Date(), 1).getTime() / 1000000) * 1000
        const path = `/v8/finance/chart/${ticker.toUpperCase()}?period1=${yesterday}&period2=${now}&interval=1d`
        const response = await fetch(path)
        return await response.json()
    }
}
