import React, {useEffect, useState} from 'react'
import {StockDetail} from './StockDetail.tsx'
import {useParams} from "react-router-dom";
import StockApiClient, {StockDetailResponse} from "../../apiclients/StockApiClient.ts";
import YahooFinancialApiClient, {ChartResponse} from "../../apiclients/YahooFinancialApiClient.ts";

const stockApiClient = new StockApiClient()
const yahooFinancialApiClient = new YahooFinancialApiClient()

export default function StockDetailPage() {
    const {ticker: tickerLowercase} = useParams<'ticker'>()
    const [stockInfo, setStockInfo] = useState<ChartResponse>()
    const [stockDetail, setStockDetail] = useState<StockDetailResponse>()
    const [loading, setLoading] = useState(false)

    const ticker = tickerLowercase?.toUpperCase() ?? '???'

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() =>
                Promise.all([
                    yahooFinancialApiClient.getStock(ticker).then(setStockInfo),
                    stockApiClient.getStockDetail(ticker).then(setStockDetail),
                ])
            )
            .finally(() => setLoading(false))
    }, [ticker])

    return (
        <StockDetail
            meta={stockInfo?.chart.result[0].meta}
            detail={stockDetail}
            loading={loading}
        />
    )
}
