import React from 'react';
import {Layout, Menu} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {Link, Route, Routes} from "react-router-dom";
import StockListPage from './pages/stock/StockListPage.tsx';
import StockDetailPage from './pages/stock/StockDetailPage.tsx';

const {Header, Footer} = Layout;

const items = [
    {
        key: 'stocks',
        label: <Link to='/stocks'>Stocks</Link>,
    },
];

const App: React.FC = () => {
    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header style={{display: 'flex', alignItems: 'center'}}>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={[items[0].key]}
                    items={items}
                    style={{flex: 1, minWidth: 0}}
                />
            </Header>
            <Content style={{padding: '0 12px'}}>
                <Routes>
                    <Route path='/' element={<StockListPage/>}/>
                    <Route path='/stocks' element={<StockListPage/>}/>
                    <Route path='/stocks/all/:date' element={<StockListPage/>}/>
                    <Route path='/stocks/:ticker' element={<StockDetailPage/>}/>
                    <Route path='/stocks/:ticker/:date' element={<StockDetailPage/>}/>
                </Routes>
            </Content>
            <Footer style={{textAlign: 'center'}}>
                Stock ©{new Date().getFullYear()}
            </Footer>
        </Layout>
    );
};

export default App;
